import React from 'react';
import './HowItWorks.scss';

const HowItWorks = () => {
  return (
    <section className="how-it-works">
      <div className="container">
        <h2>How It Works</h2>
        <div className="steps">
          <div className="step">
            <div className="step-icon">1</div>
            <h3>Upload Your Photos</h3>
            <p>Select your best selfies and photos for analysis.</p>
          </div>
          <div className="step">
            <div className="step-icon">2</div>
            <h3>AI Analysis</h3>
            <p>Our AI analyzes your photos and dating profile information.</p>
          </div>
          <div className="step">
            <div className="step-icon">3</div>
            <h3>Get Recommendations</h3>
            <p>Receive personalized tips to improve your dating profile.</p>
          </div>
        </div>
        <div className="visual-example">
          <h3>See the Difference</h3>
          <div className="example-container">
            <div className="before-after">
              <div className="before">
                <h4>Before</h4>
                <img src="https://pbs.twimg.com/media/D7ShRPYXoAA-XXB?format=jpg&name=medium" alt="Profile Before" />
                <ul>
                  <li>"Here for a good time, not a long time 😜"</li>
                  <li>"I'm 6'2" because apparently that matters 🙄"</li>
                  <li>"Fluent in sarcasm. If you can't handle me at my worst..."</li>
                </ul>
              </div>
              <div className="after">
                <h4>After</h4>
                <img src="https://i.imgur.com/My2PYkk.jpeg" alt="Profile After" />
                <ul>
                  <li>"Adventure seeker with a passion for trying new cuisines 🌮🍣"</li>
                  <li>"Software engineer by day, amateur chef by night 👨‍💻🍳"</li>
                  <li>"Looking for someone to explore hidden gems in the city with 🏙️"</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { ChevronDown, ChevronUp } from 'lucide-react';
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from 'recharts';
import { toPng } from 'html-to-image';
import './Purchase.scss';

const Purchase = () => {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dots, setDots] = useState('.');
  const [analysisData, setAnalysisData] = useState(null);
  const [expandedSections, setExpandedSections] = useState({
    strengths: true,
    improvements: false,
    feedback: false,
    suggestions: false,
  });
  const [email, setEmail] = useState('');
  const [emailSending, setEmailSending] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [showPurchaseContainer, setShowPurchaseContainer] = useState(true);

  const chartRef = useRef(null);

  const [data, setData] = useState(null);

  useEffect(() => {
    setError(null);
  }, [files]);

  useEffect(() => {
    let interval;
    if (isLoading) {
      interval = setInterval(() => {
        setDots((prev) => (prev.length < 3 ? prev + '.' : '.'));
      }, 500);
    }
    return () => clearInterval(interval);
  }, [isLoading]);

  const handleFileChange = (event) => {
    setFiles([...files, ...Array.from(event.target.files)]);
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      setError('Please select at least one file to upload.');
      return;
    }

    setIsLoading(true);
    setError('');
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('file', file);
    });

    try {
      const response = await axios.post(
        `https://api.dateableai.com/api/upload-files`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
          timeout: 30000,
        }
      );
      console.log('Server response:', response.data);

      if (response.data.results) {
        const parsedAnalysis = response.data.results;
        console.log('Parsed analysis data:', parsedAnalysis);
        setAnalysisData(parsedAnalysis);
        if (parsedAnalysis.chart_data) {
          setData(parsedAnalysis.chart_data);
          console.log('Chart data set:', parsedAnalysis.chart_data);
        } else {
          console.log('No chart_data found in parsed analysis');
        }
        setShowPurchaseContainer(false);
      } else {
        console.log('No results found in server response');
        setError('No analysis results received from the server.');
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      if (error.response) {
        console.error('Server responded with error:', error.response.data);
        setError(`Failed to upload files: ${error.response.data.error || 'Unknown server error'}`);
      } else if (error.request) {
        console.error('No response received:', error.request);
        setError('Failed to upload files: No response received from server. Please check your connection and try again.');
      } else {
        console.error('Error setting up request:', error.message);
        setError(`Failed to upload files: ${error.message}`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  const downloadChart = () => {
    if (chartRef.current === null) {
      console.error('Chart reference is null');
      return;
    }

    toPng(chartRef.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'profile-analysis-chart.png';
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.error('Error generating chart image:', err);
      });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const sendEmail = async () => {
    if (!email) {
      alert('Please enter an email address.');
      return;
    }

    setEmailSending(true);
    try {
      const response = await axios.post(
        `https://api.dateableai.com/api/send-email`,
        {
          email,
          feedback: JSON.stringify(analysisData),
        },
        {
          withCredentials: true,
        }
      );
      console.log('Email sent:', response.data);
      setEmailSent(true);
      setEmail('');
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email. Please try again.');
    } finally {
      setEmailSending(false);
    }
  };

  const Section = ({ title, content, isExpanded, onToggle }) => (
    <div className="analysis-section">
      <button onClick={onToggle} className="section-toggle">
        <h2>{title}</h2>
        {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </button>
      {isExpanded && <div className="section-content">{content}</div>}
    </div>
  );

  const [dragActive, setDragActive] = useState(false);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFiles([...files, ...Array.from(e.dataTransfer.files)]);
    }
  };

  if (isLoading) {
    return (
      <div className="loading-container">
        <div className="loading-content">
          <div className="spinner"></div>
          <h2>Judging Your Profile</h2>
          <p>Please wait while our AI analyzes your photo{dots}</p>
        </div>
      </div>
    );
  }

  return (
    <div className={`purchase-container ${!showPurchaseContainer ? 'hidden' : ''}`}>
      {showPurchaseContainer && (
        <>
          <h1>Thank you for your purchase!</h1>
          <div className="upload-section">
            <div
              className={`drag-drop-area ${dragActive ? 'drag-active' : ''}`}
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            >
              <p>Drag and drop your files here</p>
              <div className="moving-circle"></div>
              <div className="moving-square"></div>
            </div>
            <div className="button-group">
              <input
                type="file"
                id="file-input"
                multiple
                accept=".webp,.png,.jpg,.jpeg,.mov"
                onChange={handleFileChange}
                className="file-input"
              />
              <label htmlFor="file-input" className="file-input-label">
                Choose Files
              </label>
              <button onClick={handleUpload} className="upload-button">
                Upload Files
              </button>
            </div>
            {files.length > 0 && <p>{files.length} file(s) selected</p>}
            {error && <div className="error-message">{error}</div>}
          </div>
        </>
      )}
      {analysisData && (
        <div className="dating-profile-analysis">
          <h1>Dating Profile Analysis</h1>
          <div className="analysis-container">
            <div className="text-analysis">
              <Section
                title="Strengths"
                isExpanded={expandedSections.strengths}
                onToggle={() => toggleSection('strengths')}
                content={
                  analysisData.strengths && analysisData.strengths.length > 0 ? (
                    <ul>
                      {analysisData.strengths.map((strength, index) => (
                        <li key={index}>{strength}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>No strengths found.</p>
                  )
                }
              />

              <Section
                title="Areas for Improvement"
                isExpanded={expandedSections.improvements}
                onToggle={() => toggleSection('improvements')}
                content={
                  analysisData.areas_for_improvement &&
                  analysisData.areas_for_improvement.length > 0 ? (
                    <ul>
                      {analysisData.areas_for_improvement.map((area, index) => (
                        <li key={index}>{area}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>No areas for improvement found.</p>
                  )
                }
              />

              <Section
                title="Specific Feedback"
                isExpanded={expandedSections.feedback}
                onToggle={() => toggleSection('feedback')}
                content={
                  analysisData.specific_feedback ? (
                    <div>
                      {analysisData.specific_feedback.expand_interests && (
                        <>
                          <h3>Expand on Interests:</h3>
                          <p>{analysisData.specific_feedback.expand_interests}</p>
                        </>
                      )}
                      {analysisData.specific_feedback.elaborate_hobbies && (
                        <>
                          <h3>Elaborate on Hobbies:</h3>
                          <p>{analysisData.specific_feedback.elaborate_hobbies}</p>
                        </>
                      )}
                      {analysisData.specific_feedback.improve_stories && (
                        <>
                          <h3>Improve Travel Stories:</h3>
                          <p>{analysisData.specific_feedback.improve_stories}</p>
                        </>
                      )}
                    </div>
                  ) : (
                    <p>No specific feedback available.</p>
                  )
                }
              />

              <Section
                title="Improvement Suggestions"
                isExpanded={expandedSections.suggestions}
                onToggle={() => toggleSection('suggestions')}
                content={
                  analysisData.improvement_suggestions &&
                  analysisData.improvement_suggestions.length > 0 ? (
                    <div>
                      <ul>
                        {analysisData.improvement_suggestions.map(
                          (suggestion, index) => (
                            <li key={index}>{suggestion}</li>
                          )
                        )}
                      </ul>
                      {analysisData.example && (
                        <div>
                          <h3>Example:</h3>
                          <div className="example">
                            {analysisData.example.before && (
                              <>
                                <h4>Before:</h4>
                                <p>{analysisData.example.before}</p>
                              </>
                            )}
                            {analysisData.example.after && (
                              <>
                                <h4>After:</h4>
                                <p>{analysisData.example.after}</p>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <p>No improvement suggestions available.</p>
                  )
                }
              />
            </div>
            <div className="chart-analysis" ref={chartRef}>
              <h2>Profile Attribute Scores</h2>
              {data ? (
                <ResponsiveContainer width="100%" height={400}>
                  <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
                    <PolarGrid />
                    <PolarAngleAxis dataKey="attribute" />
                    <PolarRadiusAxis angle={30} domain={[0, 10]} />
                    <Radar
                      name="Profile"
                      dataKey="value"
                      stroke="#FF8C00"
                      fill="#FF8C00"
                      fillOpacity={0.6}
                    />
                  </RadarChart>
                </ResponsiveContainer>
              ) : (
                <p>Loading chart data...</p>
              )}
              <button onClick={downloadChart} className="download-button">
                Download Chart
              </button>
            </div>
          </div>
          <div className="email-section">
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
              className="email-input"
            />
            <button
              onClick={sendEmail}
              className="email-button"
              disabled={emailSending}
            >
              {emailSending ? 'Sending...' : 'Email Results'}
            </button>
            {emailSent && <p className="email-success">Email sent successfully!</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default Purchase;
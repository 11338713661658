import React from 'react';
import Header from './Header';
import './Homepage.scss';

const HomePage = () => {
  return (
    <div className="home-page">
      <Header />
      <div className="content-container">
        <div className="main-content">
          <div className="text-content">
            <h1>10x your matches</h1>
            <p>Stop paying for your expensive dating app subscriptions. Get a permanent profile boost for a one-time payment</p>
            <ul className="features">
              <li>Upload selfies and get the perfect profile photo</li>
              <li>Get the vibe you're going for our prompt generation tool</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
import React from 'react';
import HomePage from './HomePage';
import Pricing from './Pricing';
import HowItWorks from './HowItWorks';
import './ScrollableLayout.scss';

const ScrollableLayout = () => {
  return (
    <div className="scrollable-layout">
      <HomePage />
      <HowItWorks />
      <Pricing />
    </div>
  );
};

export default ScrollableLayout;

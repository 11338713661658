import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import stripePromise from './stripe';
import Header from './Header';
import ScrollableLayout from './ScrollableLayout';

const App = () => {
  return (
    <Elements stripe={stripePromise}>
      <div className="app-container">
        <Header />
        <ScrollableLayout />
      </div>
    </Elements>
  );
};

export default App;

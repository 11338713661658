import React, { useState, useEffect } from 'react';
import './Pricing.scss';
import { useNavigate } from 'react-router-dom';

const PricingPlan = ({ title, price, features, buttonText, priceId, onBuyClick }) => (
  <div className="pricing-plan">
    <h3>{title}</h3>
    <div className="price">${price} <span>/ month</span></div>
    <ul>
      {features.map((feature, index) => (
        <li key={index}>{feature}</li>
      ))}
    </ul>
    <button onClick={() => onBuyClick(priceId)} className="learn-more-btn">{buttonText}</button>
  </div>
);

const Message = ({ message, isError }) => (
  <div className={`message ${isError ? 'error' : ''}`}>
    <p>{message}</p>
  </div>
);

const PricingContent = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.");
      setIsError(false);
    }
    if (query.get("canceled")) {
      setMessage("Order canceled -- continue to shop around and checkout when you're ready.");
      setIsError(false);
    }
  }, []);

  const handleBuyClick = async (priceId) => {
    try {
      console.log('Fetching: https://api.dateableai.com/create-checkout-session');
      console.log('Request body:', JSON.stringify({ priceId }));
      const response = await fetch(`https://api.dateableai.com/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ priceId }),
        credentials: 'include',
      });
      console.log('Response status:', response.status);
      
      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        throw new Error("Oops! Received an unexpected response from the server.");
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Network response was not ok');
      }
      
      const { url, sessionId } = await response.json();
      
      localStorage.setItem('checkoutSessionId', sessionId);
      
      
      window.location = url;
    } catch (error) {
      console.error('Error during checkout process:', error);
      setMessage(`Error: ${error.message || 'There was an error processing your request. Please try again.'}`);
      setIsError(true);
    }
  };

  const plans = [
    {
      title: "One-Time",
      price: "5",
      features: [
        // "1 photoset of your choice",
        "Fills out questions for one app"
      ],
      buttonText: "Get Started",
      priceId: "price_1PoA47CQIW0leZowOpLA1kLs"
    }
    // {
    //   title: "Two Apps",
    //   price: "12",
    //   features: [
    //     "4 photosets of your choice",
    //     "Fills out questions for two apps"
    //   ],
    //   buttonText: "Get Started",
    //   priceId: "price_1PoA48CQIW0leZowXxXxXxXx"
    // },
    // {
    //   title: "All Apps",
    //   price: "20",
    //   features: [
    //     "All 10 photosets",
    //     "Fills out questions for any app"
    //   ],
    //   buttonText: "Get Started",
    //   priceId: "price_1PoA49CQIW0leZowYyYyYyYy"
    // }
  ];

  if (message) {
    return <Message message={message} isError={isError} />;
  }

  return (
    <section className="pricing-section">
      <div className="container">
        <h2>Choose Your Plan</h2>
        <div className="pricing-plans">
          {plans.map((plan, index) => (
            <PricingPlan key={index} {...plan} onBuyClick={handleBuyClick} />
          ))}
        </div>
      </div>
    </section>
  );
};

const Pricing = () => (
  <PricingContent />
);

export default Pricing;